import React from "react"
import {
  FormControl,
  FormHelperText,
  FormLabel,
  InputGroup,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  LayoutProps,
} from "@chakra-ui/react"
import { Control, FieldValues, useController } from "react-hook-form"

type Props<T extends FieldValues> = {
  name: string;
  control: Control<T, object>;
  label?: string;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  min?: number;
  max?: number;
  vertical?: boolean;
  step?: number;
  w?: LayoutProps["w"]
}

const NumberField = <T extends FieldValues, >({
  name,
  control,
  label,
  disabled,
  error,
  helperText,
  min,
  max,
  vertical,
  step = 1,
  w,
}: Props<T>) => {
  const { field } = useController<any>({ name, control })

  return (
    <FormControl isDisabled={disabled} mb={4} display={vertical ? "block" : "flex"} isInvalid={error}>
      {label && <FormLabel fontWeight="bold" w={vertical ? "auto" : "25%"}>{label}</FormLabel>}
      <Box w="100%">
        <InputGroup>
          <NumberInput
            {...field}
            onChange={(value: string) => {
              let number = Number(value)
              if (!number || (min && number < min)) {
                number = min || 0
              } else if (max && number > max) {
                number = max
              }
              field.onChange(number)
            }}
            min={min}
            max={max}
            step={step}
            w={w}
          >
            <NumberInputField bg="white" />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </InputGroup>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Box>
    </FormControl>
  )
}

export default NumberField
