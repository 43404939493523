import React from "react"
import { Box, Stack } from "@chakra-ui/react"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { AutomatedEmails } from "pages/EventShow/Settings/EmailNotifications"
import { useCurrentEvent } from "queries/events"
import TextField from "components/ReactHookForm/TextField"
import { hasValidCustomDomain } from "utilities/strings"
import { notificationsReplyToEmailOptions } from "utilities/objects"
import { AccountUser, FormsAnchor } from "sharedTypes"
import Select from "components/ReactHookForm/Select"
import { DATE_FORMAT_OPTIONS, EMAIL_USER_VALIDATION_REGEX } from "utilities/constants"
import Checkbox from "components/ReactHookForm/Checkbox"
import PageContent from "components/layout/PageContent"
import TextArea from "components/ReactHookForm/TextArea"
import * as Yup from "yup"
import TitleWithSubtitle from "../TitleWithSubtitle"

type Props = {
  updateEvent: (params: {}) => Promise<void>
  accountUsers: AccountUser[]
}

const EmailResponders = ({ updateEvent, accountUsers }: Props) => {
  const { data: currentEvent, data: { sendConfirmationEmails, users } } = useCurrentEvent()

  const validCustomDomain = hasValidCustomDomain(currentEvent)

  const validationSchema = Yup.object({
    emailNotificationsSenderMailbox: validCustomDomain
      ? Yup.string()
        .matches(
          EMAIL_USER_VALIDATION_REGEX,
          "Invalid email",
        )
      : Yup.string().nullable(),
  })

  const replyToOptions = notificationsReplyToEmailOptions(
    users, accountUsers, currentEvent.emailNotificationsReplyTo,
  )
  const onSubmit = (values) => updateEvent(values)

  return (
    <PageContent>
      <Box pb={10}>
        <Box>
          <TitleWithSubtitle title="Automated Email Confirmations for Guests" />
          <AutomatedEmails sendConfirmationEmails={sendConfirmationEmails} onSubmit={onSubmit} />
        </Box>
        <Box mt={20}>
          <TitleWithSubtitle
            title="Email Notifications for your Guests"
            subtitle="When anyone orders a ticket or confirms an RSVP, they will receive an automated
      confirmation message using the details below:"
          />
          <ContainedForm
            values={currentEvent}
            onSubmit={onSubmit}
            name="Email Notifications for your Guests"
            validationSchema={validationSchema}
          >
            {({ control }) => (
              <Stack spacing={12}>
                <Box>
                  <TextField
                    name="emailNotificationsEventName"
                    control={control}
                    label="Event Title (override)"
                    required
                  />
                  <TextField
                    name="emailNotificationsSenderName"
                    control={control}
                    label="Sender Name"
                  />
                  {validCustomDomain ? (
                    <TextField
                      name="emailNotificationsSenderMailbox"
                      control={control}
                      label="Sender Email"
                      rightElement={`@${currentEvent.customDomain?.domain}`}
                    />
                  ) : (
                    <Select
                      name="emailNotificationsReplyTo"
                      control={control}
                      label="Reply-To Email"
                      options={replyToOptions}
                    />
                  )}
                  <TextArea
                    name="emailNotificationsCustomMessage"
                    control={control}
                    label="Custom Message"
                    helperText="This message will only be shown at the bottom of the confirmation email."
                    h={48}
                  />
                </Box>
              </Stack>
            )}
          </ContainedForm>
        </Box>
        <Box id={FormsAnchor.GuestConfirmationSettings} mt={20}>
          <TitleWithSubtitle
            title="Guest Confirmation Settings"
            subtitle="The following apply to components that are shown on the various Guest Portal pages (including registration confirmations) and are sent to guests in automated email messages."
          />
          <ContainedForm values={currentEvent} onSubmit={onSubmit} name="Guest Confirmation Settings">
            {({ control }) => (
              <Stack>
                <Box>
                  <Select
                    name="dateFormat"
                    control={control}
                    label="Date Format"
                    options={DATE_FORMAT_OPTIONS}
                    helperText="Specify the date format that appears in the subject line of the automated email confirmations."
                  />
                </Box>
                <Stack spacing={8}>
                  <Checkbox
                    name="showDateInConfirmationEmail"
                    control={control}
                    label="Show event start date on confirmations"
                    leftLabel="Event Date"
                    mb={0}
                  />
                  <Checkbox
                    name="attachIcs"
                    control={control}
                    label="Attach calendar invite and show “Add to Calendar” options"
                    leftLabel="Calendar Invite"
                    mb={0}
                  />
                  <Checkbox
                    name="includeSeatingInfo"
                    control={control}
                    label="Display table name and seat number"
                    leftLabel="Seating Info"
                    mb={0}
                  />
                  <Checkbox
                    name="includeQrCode"
                    control={control}
                    label="Include QR Code"
                    leftLabel="QR Code"
                    mb={0}
                  />
                  <Checkbox
                    name="includePassbook"
                    control={control}
                    label="Include “Add to Apple Wallet”"
                    leftLabel="Wallet Pass"
                    mb={0}
                  />
                </Stack>
              </Stack>
            )}
          </ContainedForm>
        </Box>
      </Box>
    </PageContent>
  )
}

export default EmailResponders
