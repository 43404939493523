import React from "react"
import { Box, HStack, Text } from "@chakra-ui/react"
import Popover from "components/dialogs/Popover"
import CopyToClipboard from "components/elements/CopyToClipboard"
import Link from "components/elements/Link"
import { DioboxEvent } from "sharedTypes"
import { getGuestPortalUrl } from "utilities/externalLinks"
import Button from "components/Buttons/Button"

type Props = {
  event: DioboxEvent

}

const SharePopover = ({ event: { externalId } }: Props) => {
  const guestPortalUrl = getGuestPortalUrl(externalId)

  return (
    <Popover
      placement="bottom-start"
      arrow={false}
      trigger={<Button variant="outline" size="sm" rightIcon="down-arrow">Share</Button>}
      body={(
        <Box p={6}>
          <Text fontWeight="semibold">Guest Portal Forms</Text>
          <Link isExternal href={guestPortalUrl}><Text fontWeight="bold" fontSize="lg">{guestPortalUrl}</Text></Link>
          <HStack mt={2}>
            <CopyToClipboard size="sm" text={guestPortalUrl} variant="outline" leftIcon="copy">Copy</CopyToClipboard>
            <Link isExternal href={guestPortalUrl}><Button size="sm" leftIcon="link-external" variant="outline">View</Button></Link>
          </HStack>
        </Box>
      )}
    />
  )
}

export default SharePopover
