import {
  Box,
  Button,
  FormLabel,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  FormControl,
  SpaceProps,
  Portal,
  Text,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import React, { ReactNode } from "react"
import { ChakraProps } from "@chakra-ui/system"
import { Control, FieldValues, useController } from "react-hook-form"

type Props<Type, T extends FieldValues> = {
  name: string
  control: Control<T, object>
  options?: {label: ReactNode, value: Type}[]
  label?: string
  vertical?: boolean
  maxListHeight?: number
  mb?: SpaceProps["margin"]
} & ChakraProps

const SelectMenu = <T extends FieldValues, >({
  name, control, options = [], label, vertical, maxListHeight, mb = 4, ...props
}: Props<number|string|boolean, T>) => {
  const { field } = useController<any>({ name, control })

  const typeOfValues = typeof options[0]?.value

  const valueToString = (value) => (typeOfValues === "boolean" ? `${Number(value)}` : `${value}`)
  const stringToValue = (string) => {
    switch (typeOfValues) {
      case "boolean":
        return !!Number(string)
      case "number":
        return Number(string)
      default:
        return string
    }
  }

  const selectedOption = options.find(({ value }) => value === field.value) || options[0]

  return (
    <FormControl mb={mb} display="flex" alignItems={vertical ? "initial" : "center"}>
      {label && <FormLabel fontWeight="bold" w={vertical ? "auto" : 1 / 4} mb={0}>{label}</FormLabel>}
      <Box w="100%">
        <Menu>
          <MenuButton
            as={Button}
            w="full"
            borderRadius="md"
            borderWidth={1}
            bg="white"
            rightIcon={<Icon icon="down-arrow" />}
            textAlign="left"
            {...props}
          >
            <Text
              isTruncated
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {selectedOption?.label}
            </Text>
          </MenuButton>
          {!!options.length && (
            <Portal>
              <MenuList minWidth="240px" {...maxListHeight ? { maxHeight: maxListHeight, overflow: "scroll" } : {}}>
                <MenuOptionGroup
                  onChange={(value) => {
                    field.onChange(stringToValue(value))
                  }}
                  value={valueToString(field.value)}
                >
                  {options.map(({ label: optionLabel, value }, index) => (
                    <MenuItemOption key={index} value={valueToString(value)}>
                      {optionLabel}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Portal>
          )}
        </Menu>
      </Box>
    </FormControl>
  )
}

export default SelectMenu
