import React from "react"
import {
  Flex, Input, InputGroup, InputLeftElement, InputRightElement, Button,
} from "@chakra-ui/react"
import Icon from "components/elements/Icon"
import Spinner from "components/elements/Spinner"

type Props = {
  value: string;
  onChange: (value: string) => void;
  loading: boolean;
  placeholder?: string;
  variant?: "outline" | "unstyled";
  padded?: boolean;
}

const SearchField = ({
  value, onChange, loading, placeholder = "Search", variant = "outline", padded,
}: Props) => (
  <Flex w="100%" px={padded ? 10 : 5}>
    <InputGroup>
      <InputLeftElement pointerEvents="none">
        <Icon icon="guest-search" color="gray.500" />
      </InputLeftElement>
      <Input
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        variant={variant}
        type="text"
        py={2}
      />
      <InputRightElement>
        {
          loading
            ? <Spinner heightFull size="md" />
            : (
              value.length > 0 && (
              <Button variant="link" onClick={() => onChange("")} cursor="pointer" data-testid="clear-guest-text-search">
                <Icon icon="times" color="blue.500" size={3.5} />
              </Button>
              )
            )
        }
      </InputRightElement>
    </InputGroup>
  </Flex>
)

export default SearchField
