import React from "react"
import Icon from "./Icon"

type Props = {
  title: string
  icon: string | null
}

const EventGroupItem = ({ icon, title }: Props) => (
  <><Icon icon={icon ? `event-group-${icon}` : "tag"} size={5} mr={2} mt={-1} color="gray.500" /> {title}</>
)

export default EventGroupItem
