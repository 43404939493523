import React, { ReactNode } from "react"
import {
  Flex, Stack, Table, Td, Tbody, Text, Th, Thead, Tr, Box,
} from "@chakra-ui/react"
import { CustomDomain } from "sharedTypes"
import { checkCustomDomain } from "api/events"
import Icon from "components/elements/Icon"
import { updateCustomDomain } from "context/actions"
import { useQueryClient } from "react-query"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import TextareaWithCopyButton from "components/inputs/TextareaWithCopyButton"
import Link from "components/elements/Link"
import { useIntercom } from "react-use-intercom"

interface Props {
  eventId: string
  domain: CustomDomain
  onHide: () => void
}

const CustomDomainInstructions = ({
  eventId,
  domain: {
    domain,
    id,
    verifiedAt,
    verifyTxtKey,
    dkim,
    dkim2,
    dmarc,
  }, onHide,
}: Props) => {
  const queryClient = useQueryClient()
  const { show: showIntercom } = useIntercom()

  const verified = <Text color="green.400" fontWeight="semibold"><Icon icon="checkmark" /> Verified</Text>
  const notVerified = <Text color="red.400" fontWeight="semibold"><Icon icon="times" /> Not Verified</Text>

  const onSubmit = () => checkCustomDomain(eventId, id).then(({ data }) => {
    updateCustomDomain(queryClient, data)
  })

  return (
    <ModalForm
      onSubmit={onSubmit}
      submitText="Check DNS Records"
      onClose={onHide}
      title="Verify Domain Ownership"
      size="4xl"
      hideCancel
    >
      <Stack spacing={5}>
        <Flex>
          <Box>
            <Text fontSize="2xl" mr={1}>Domain Name:</Text>
          </Box>
          <Text fontSize="2xl" fontWeight="bold">{domain}</Text>
        </Flex>
        <Text>
          In order to verify the domain ownership, you or your IT administrator will
          need to add the following entries to your DNS records. Depending on your DNS
          setup, this process could take between a few minutes to 48 hours to fully
          propagate. If you require further technical assistane, please {" "}
          <Link onClick={showIntercom}>send us a message</Link>.
        </Text>
        <Table>
          <Thead>
            <Tr>
              <Th />
              <Th>Type</Th>
              <Th>Name</Th>
              <Th>Value</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <TopAlignedTd>
                <Text fontWeight="bold" mb={1}>Domain Verification</Text>
                {verifiedAt ? verified : notVerified}
              </TopAlignedTd>
              <TopAlignedTd>TXT</TopAlignedTd>
              <TopAlignedTd>
                <TextareaWithCopyButton text={domain} />
              </TopAlignedTd>
              <TopAlignedTd>
                <TextareaWithCopyButton text={verifyTxtKey ? `mandrill_verify.${verifyTxtKey}` : ""} />
              </TopAlignedTd>
            </Tr>
            <Tr>
              <TopAlignedTd noBottomBorder>
                <Text fontWeight="bold" mb={1}>DKIM</Text>
                { (dkim.valid && dkim2.valid) ? verified : notVerified }
              </TopAlignedTd>
              <TopAlignedTd noBottomBorder>CNAME</TopAlignedTd>
              <TopAlignedTd noBottomBorder>
                <TextareaWithCopyButton text={`mte1._domainkey.${domain}`} />
              </TopAlignedTd>
              <TopAlignedTd noBottomBorder>
                <TextareaWithCopyButton text="dkim1.mandrillapp.com" />
              </TopAlignedTd>
            </Tr>
            <Tr>
              <TopAlignedTd />
              <TopAlignedTd>CNAME</TopAlignedTd>
              <TopAlignedTd>
                <TextareaWithCopyButton text={`mte2._domainkey.${domain}`} />
              </TopAlignedTd>
              <TopAlignedTd>
                <TextareaWithCopyButton text="dkim2.mandrillapp.com" />
              </TopAlignedTd>
            </Tr>
            <Tr>
              <TopAlignedTd noBottomBorder>
                <Text fontWeight="bold" mb={1}>DMARC</Text>
                { dmarc.valid ? verified : notVerified }
              </TopAlignedTd>
              <TopAlignedTd noBottomBorder>TXT</TopAlignedTd>
              <TopAlignedTd noBottomBorder>
                <TextareaWithCopyButton text={`_dmarc.${domain}`} />
              </TopAlignedTd>
              <TopAlignedTd noBottomBorder>
                <TextareaWithCopyButton text="v=DMARC1; p=none;" />
              </TopAlignedTd>
            </Tr>
          </Tbody>
        </Table>
      </Stack>
    </ModalForm>
  )
}

type TopAlignedTdProps = {
  children?: ReactNode
  noBottomBorder?: boolean
}

const TopAlignedTd = ({ children, noBottomBorder }: TopAlignedTdProps) => (
  <Td verticalAlign="top" borderBottomWidth={noBottomBorder ? 0 : 1}>
    {children}
  </Td>
)

export default CustomDomainInstructions
