import React from "react"
import ModalForm from "components/dialogs/ReactHookForm/ModalForm"
import * as Yup from "yup"
import EditModalSegment from "pages/EventShow/Forms/Terminology/FormSpecificMessagingSection/EditModalSegment"
import TranslationField from "pages/EventShow/Forms/Terminology/TranslationField"
import { DioboxEvent, Translations } from "sharedTypes"

export type RegistrationFormsModalProps = {
  title: string
  event:DioboxEvent
  onClose: () => void
  onSubmit: (values) => Promise<void>
  defaultTranslations: Translations
}

const SurveyFormsModal = ({
  title, event, onClose, onSubmit, defaultTranslations,
}: RegistrationFormsModalProps) => {
  const SurveyFormsModalSchema = Yup.object().shape({})

  return (
    <ModalForm
      title={`Messaging: ${title}`}
      onClose={onClose}
      initialValues={event}
      validationSchema={SurveyFormsModalSchema}
      onSubmit={onSubmit}
    >
      {({ control }) => (
        <>
          <EditModalSegment title="Form Button" first>
            <TranslationField
              label="Submit Button Label"
              defaultTranslation={defaultTranslations.surveySubmitButton}
              fieldName="translations.surveySubmitButton"
              editable
              control={control}
            />
          </EditModalSegment>
          <EditModalSegment title="Form Messages" last>
            <TranslationField
              label="Success Message"
              defaultTranslation={defaultTranslations.surveySuccessMessage}
              fieldName="translations.surveySuccessMessage"
              editable
              control={control}
            />
            <TranslationField
              label="Form Edited Message"
              defaultTranslation={defaultTranslations.surveyEditMessage}
              fieldName="translations.surveyEditMessage"
              editable
              control={control}
            />
            <TranslationField
              label="Closing Message"
              defaultTranslation={defaultTranslations.surveyClosingMessage}
              fieldName="translations.surveyClosingMessage"
              editable
              control={control}
            />
          </EditModalSegment>
        </>
      )}
    </ModalForm>
  )
}

export default SurveyFormsModal
