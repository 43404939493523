import React, { useCallback, useState } from "react"
import _ from "lodash"
import Icon from "components/elements/Icon"
import { ModalName } from "sharedTypes"
import Popover from "components/dialogs/Popover"
import {
  Box, Flex, Link, Text,
} from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import useModal from "services/useModal"
import { useForm } from "react-hook-form"
import Checkboxes from "components/ReactHookForm/Checkboxes"
import { useDeepCompareEffect } from "ahooks"
import SimpleBar from "simplebar-react"
import SearchField from "components/inputs/SearchField"
import EventGroupItem from "components/elements/EventGroupItem"

type Props = {
  eventGroups: { id: number; title: string; icon: string|null; }[]
  updateGuest: (params: {}) => void
  disabled: boolean
}

const GuestLists = ({ eventGroups, updateGuest, disabled }: Props) => {
  const { data: currentEvent } = useCurrentEvent()
  const showModal = useModal()
  const [searchString, setSearchString] = useState<string>("")

  const allGroups = _.sortBy(
    (currentEvent.eventGroups || []), (group) => [group.icon, group.title.toLowerCase()],
  )

  let toggle
  if (eventGroups.length) {
    toggle = _.sortBy(eventGroups, (group) => [group.icon, group.title.toLowerCase()])
      .map(({ id, title, icon }) => (
        <Flex
          align="center"
          mb={3}
          mx={4}
          bg="none"
          color="gray.500"
          fontSize="lg"
          key={id}
        >
          <Icon icon={icon ? `event-group-${icon}` : "tag"} mr={2} /> {title}
        </Flex>
      ))
  } else {
    toggle = (
      <Flex align="center" mb={3} color="gray.500" fontSize="lg">
        <Icon icon="tag" mr={2} /> No Guest List Selected
      </Flex>
    )
  }

  const onSubmit = ({ eventGroups: eg }) => updateGuest({
    eventGroups: allGroups.filter(({ id }) => eg.includes(id)),
  })

  const eventGroupIds = eventGroups.map((g) => g.id)

  const {
    control, handleSubmit, watch, reset,
  } = useForm({
    defaultValues: { eventGroups: eventGroupIds },
  })

  const submitHandler = handleSubmit(onSubmit)

  const debouncedSubmitHandler = useCallback(
    _.debounce(submitHandler, 500),
    [updateGuest, ...allGroups],
  )

  const egs = watch("eventGroups")

  useDeepCompareEffect(() => {
    reset({ eventGroups: eventGroupIds })
  }, [eventGroups])

  useDeepCompareEffect(() => {
    if (!_.isEqual(egs, eventGroupIds)) {
      debouncedSubmitHandler()
    }
  }, [egs])

  const filteredGroups = allGroups.filter(
    ({ title }) => title.toLowerCase().includes(searchString.toLowerCase()),
  )

  return (
    <Popover
      header="Guest List"
      arrow
      body={(
        <>
          <Box p={2} borderBottomWidth={1}>
            <SearchField
              onChange={(value) => setSearchString(value)}
              value={searchString}
              variant="unstyled"
            />
          </Box>
          <Box px={4} pt={4} maxHeight={64} as={SimpleBar}>
            {filteredGroups.length === 0 && <Text mb={4} ml={8} color="gray.600">No Guests Lists found.</Text>}
            <form>
              <Checkboxes
                name="eventGroups"
                control={control}
                items={filteredGroups}
                itemComponent={EventGroupItem}
              />
            </form>
          </Box>
        </>
      )}
      footer={(
        <Link
          display="block"
          p={2}
          textAlign="center"
          fontSize="lg"
          color="blue.500"
          onClick={() => showModal(ModalName.GuestListForm)}
        >
          <Icon icon="plus-big" mr={4} />
          New Guest List
        </Link>
      )}
      trigger={(
        <Flex borderWidth={1} borderRadius="base" position="relative" align="center" justify="center" wrap="wrap" mt={2} px={4} pt={4} pb={1}>
          {toggle}
          {!disabled && (
          <Box position="absolute" right={0} mb={3}>
            <Icon icon="down-arrow" size={3} mr={2} color="gray.300" />
          </Box>
          )}
        </Flex>
      )}
      onOpen={() => setSearchString("")}
    />
  )
}

export default GuestLists
