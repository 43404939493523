import React from "react"
import { Stack } from "@chakra-ui/react"
import FormSection from "pages/EventShow/Forms/Forms/FormSection"
import { Form } from "sharedTypes"
import RSVPRadioButtons from "components/ReactHookForm/RSVPRadioButtonsField/RSVPRadioButtons"
import GuestDetails from "components/elements/GuestDetails"
import DynamicInputFields from "../DynamicInputFields"

type Props = {
  positiveAnswer: string
  negativeAnswer: string
  form: Form
  onClick?: () => void
  checkboxColor: string
  showRSVPRadioButtons?: boolean
}

const GuestNameBlock = ({
  positiveAnswer, negativeAnswer, form, onClick, checkboxColor, showRSVPRadioButtons = true,
}: Props) => (
  <FormSection onEdit={onClick} first>
    <Stack spacing={5} my={1}>
      <GuestDetails name="Guest Name" email="guest-email@example.com" />
      {showRSVPRadioButtons && (
        <RSVPRadioButtons
          defaultValue="positive"
          options={[{ label: positiveAnswer, value: "positive" }, { label: negativeAnswer, value: "negative" }]}
          name="attend"
          color={checkboxColor}
        />
      )}
    </Stack>
    <DynamicInputFields form={form} enableNameAndEmailToggle showTopDivider />
  </FormSection>
)

export default GuestNameBlock
