import React from "react"
import {
  Box, Flex, Grid, GridItem, HStack, Text,
} from "@chakra-ui/react"
import { asDate, asDateWithTZ } from "utilities/strings"
import Button from "components/Buttons/Button"
import { useHistory, useParams } from "react-router"
import { eventEmailSetupPath } from "utilities/routes"
import Card from "components/layout/Card"
import { useQueryClient } from "react-query"
import { putInvitation } from "api/emails"
import { updateEmail } from "context/actions"
import { successToast } from "utilities/toasts"
import { Email } from "sharedTypes"
import EmailOptionsMenu from "./EmailOptionsMenu"

type Props = {
  email: Email
  fromEmail: string,
  onEditName: () => void
  onDuplicate: () => void
  onDelete: () => void
  timezone: string
  onViewReport?: () => void
  onExportReport?: () => void
}

const EmailHeader = ({
  email,
  email: {
    id: emailId, title, sentOn, sendScheduledAt, subject,
  },
  fromEmail,
  onEditName,
  onDuplicate,
  onDelete,
  onViewReport,
  onExportReport,
  timezone,
}: Props) => {
  const { eventId } = useParams<{ eventId: string }>()
  const history = useHistory()
  const queryClient = useQueryClient()

  const handleClickCancelSend = () => {
    putInvitation(eventId, emailId, {
      sendScheduledAt: null,
    }).then(({ data }) => {
      updateEmail(queryClient, eventId, data)
      successToast({
        title: "Scheduled send canceled and message reverted to draft.",
        status: "success",
      })
    })
  }

  return (
    <Box w="full">
      <Flex justify="space-between" align="flex-start">
        <Text fontSize="2xl" fontWeight="semibold" mb={2}>{title}</Text>
        {!sentOn ? (
          <HStack>
            <Button
              variant="outline"
              size="sm"
              onClick={() => history.push(eventEmailSetupPath(eventId, emailId))}
            >Edit Draft
            </Button>
            <EmailOptionsMenu
              email={email}
              label="More"
              onEditName={onEditName}
              onDuplicate={onDuplicate}
              onDelete={onDelete}
              onViewReport={onViewReport}
              onExportReport={onExportReport}
            />
          </HStack>
        ) : (
          <EmailOptionsMenu
            email={email}
            label="Email Options"
            onEditName={onEditName}
            onDuplicate={onDuplicate}
            onDelete={onDelete}
            onViewReport={onViewReport}
            onExportReport={onExportReport}
          />
        )}
      </Flex>
      <Box mt={2}>
        <Card
          header={
            sendScheduledAt && !sentOn
              ? (
                <>
                  <Text fontSize="lg" fontWeight="semibold">Send scheduled for {asDateWithTZ(sendScheduledAt, timezone)}</Text>
                  <Button variant="outline" size="sm" onClick={handleClickCancelSend}>Cancel Send</Button>
                </>
              )
              : undefined
          }
        >
          <Grid templateColumns="12% 1fr">
            {sentOn && (
            <>
              <GridItem><Text fontWeight="normal">Sent</Text></GridItem>
              <GridItem><Text>{asDate(sentOn)}</Text></GridItem>
            </>
            )}
            <GridItem><Text fontWeight="normal">From</Text></GridItem>
            <GridItem><Text>{fromEmail}</Text></GridItem>
            <GridItem><Text fontWeight="normal">Subject</Text></GridItem>
            <GridItem><Text fontWeight="semibold">{subject}</Text></GridItem>
          </Grid>
        </Card>
      </Box>
    </Box>
  )
}

export default EmailHeader
