import React from "react"
import { Box, VStack } from "@chakra-ui/react"
import UpgradeAlert from "components/elements/UpgradeAlert"
import TableListItem from "../TableListItem"

const Tables = ({ tables, overTablesLimit }) => (
  <Box my={10}>
    <VStack spacing={8} mb={8} data-testid="table-list">
      {tables.map((table) => (
        <TableListItem
          key={table.id}
          table={table}
          autoSave
        />
      ))}
    </VStack>
    {overTablesLimit
      && <UpgradeAlert text="Seating limit reached. Upgrade your subscription to add more tables and rows." />}
  </Box>
)

export default Tables
