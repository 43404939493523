import React from "react"
import Menu, { MenuItem } from "components/dialogs/Menu"
import { Divider } from "@chakra-ui/react"
import ConfirmDelete from "components/dialogs/Confirm/ConfirmDelete"
import useModal from "services/useModal"
import { Email, ModalName } from "sharedTypes"

type Props = {
  label: string
  email: Email
  onEditName?: () => void
  onDuplicate?: () => void
  onDelete?: () => void
  onViewReport?: () => void
  onExportReport?: () => void
}

const EmailOptionsMenu = ({
  label,
  email,
  email: { sentOn, sendScheduledAt },
  onEditName,
  onDuplicate,
  onDelete,
  onViewReport,
  onExportReport,
}: Props) => {
  const showModal = useModal()

  const reportItems = sentOn
    ? [
      <Divider />,
      sentOn && onViewReport ? <MenuItem onClick={onViewReport}>View Email Report</MenuItem> : null,
      sentOn && onExportReport
        ? <MenuItem onClick={onExportReport}>Export Email Report</MenuItem>
        : null,
    ] : []

  const scheduleItem = !sentOn && !sendScheduledAt
    ? (
      <MenuItem onClick={() => showModal(ModalName.ScheduleSendEmail, { email })}>
        Schedule Send
      </MenuItem>
    )
    : null

  return (
    <Menu
      buttonText={label}
      menuItems={[
        <MenuItem onClick={onEditName}>Rename</MenuItem>,
        <MenuItem onClick={onDuplicate}>Duplicate Email</MenuItem>,
        scheduleItem,
        ...reportItems,
        <Divider />,
        <ConfirmDelete
          type="Email"
        >
          <MenuItem onClick={onDelete} color="red.700">Delete</MenuItem>
        </ConfirmDelete>,
      ].filter(Boolean)}
    />
  )
}

export default EmailOptionsMenu
