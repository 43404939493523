import React, { useCallback, useState } from "react"
import { Email as EmailType, ModalName } from "sharedTypes"
import {
  Box, Center, Link, Text,
} from "@chakra-ui/react"
import _ from "lodash"
import { eventInvitationUploadsUrl } from "api/emails"
import Button from "components/Buttons/Button"
import { BuilderBlock, DraggableItem } from "sharedTypes/builder"
import useModal from "services/useModal"
import BuildingBlock, { BlockInsertPosition } from "components/Builder/BuildingBlock"
import BuilderLayout from "components/Builder/BuilderLayout"
import EditorPanel from "components/Builder/EditorPanel/EditorPanel"
import BlockContent from "components/Builder/BlockContent"
import EmailBuilderPanel from "components/Builder/EmailBuilderPanel"
import SettingsPanel from "components/Builder/SettingsPanel"
import {
  handleAddBlock, handleBlockContentChange, handleDeleteBlock, handleMove,
} from "components/Builder/helpers"

type Props = {
  email: EmailType
  onChange: (email: Partial<EmailType>) => void
}

const Email = ({
  email,
  email: { emailData: { blocks: initialBlocks }, showUnsubscribe },
  onChange,
}: Props) => {
  const showModal = useModal()
  const [blocks, setBlocks] = useState<{block: BuilderBlock, blockId: number}[]>(
    initialBlocks.map((block, index) => ({ block, blockId: index })),
  )
  const [selectedBlockId, setSelectedBlockId] = useState<number>()
  const [showSettingsPanel, setShowSettingsPanel] = useState(false)

  const selectedBlock = blocks.find(({ blockId }) => blockId === selectedBlockId)

  const save = (newBlocks) => {
    onChange({
      emailData: {
        ...email.emailData,
        blocks: newBlocks.map(({ block }) => block),
      },
    })
  }

  const debouncedSave = useCallback(_.debounce((newBlocks) => save(newBlocks), 750), [onChange])

  const handleDelete = () => handleDeleteBlock(setBlocks, selectedBlockId, debouncedSave)

  const handleEndMove = (item: DraggableItem, blockId: number, position: BlockInsertPosition) => {
    if (item.blockTypeToAdd) {
      handleAddBlock({
        type: item.blockTypeToAdd,
        setBlocks,
        setSelectedBlockId,
        position,
        blockId,
        debouncedSave,
      })
    }

    save(blocks)
  }

  const handleDropIntoDropHereBox = (item: DraggableItem) => {
    if (item.blockTypeToAdd) {
      handleAddBlock({
        type: item.blockTypeToAdd,
        setBlocks,
        setSelectedBlockId,
        position: BlockInsertPosition.Bottom,
      })
    }
  }

  const uploadUrl = eventInvitationUploadsUrl(email.eventId, email.id)

  return (
    <BuilderLayout
      sidePanel={(
        <SidePanel {...{
          email,
          onChange,
          selectedBlock,
          selectedBlockId,
          setSelectedBlockId,
          uploadUrl,
          showSettingsPanel,
          setShowSettingsPanel,
          setBlocks,
          debouncedSave,
        }}
        />
      )}
      bg={email.backgroundColor}
      handleDropIntoDropHereBox={handleDropIntoDropHereBox}
      showDropHereBox={blocks.length === 0}
    >
      <Box position="relative" pt={6} overflow="hidden" minHeight="30rem" pb={40}>
        {blocks.length !== 0 && <Box position="absolute" height="full" top={0} right={0} bottom={0} left={0} onClick={() => setSelectedBlockId(undefined)} />}
        <Center mb={4}>
          <Button
            onClick={() => showModal(ModalName.SendTestEmail, { id: email.id })}
            size="xs"
            colorScheme="button.light"
            variant="outline"
          >Send Test Email
          </Button>
        </Center>
        <Box mx="auto" w={600} borderWidth={1} borderColor={email.borderColor}>
          {blocks.map(({ block, blockId }) => (
            <BuildingBlock
              key={blockId}
              dragId={blockId}
              onMove={(
                fromDragId: number,
                toDragId: number,
                position: BlockInsertPosition,
              ) => handleMove(blocks, setBlocks, fromDragId, toDragId, position)}
              onDropInto={handleEndMove}
              selected={selectedBlockId === blockId}
              onSelect={() => setSelectedBlockId(blockId)}
              onDuplicate={() => handleAddBlock({
                type: block.type,
                setBlocks,
                setSelectedBlockId,
                position: BlockInsertPosition.Bottom,
                blockId,
                block,
                debouncedSave,
              })}
              onDelete={handleDelete}
              type={block.type}
            >
              <BlockContent block={block} />
            </BuildingBlock>
          ))}
        </Box>
        {showUnsubscribe && (
        <Text align="center" mt={8} color="gray.600" fontSize="sm">
          Don&apos;t want to receive invitations from us? <Link textDecoration="underline" href="#">Unsubscribe</Link>.
        </Text>
        )}
      </Box>
    </BuilderLayout>
  )
}

const SidePanel = ({
  email,
  onChange,
  selectedBlock,
  selectedBlockId,
  setSelectedBlockId,
  uploadUrl,
  showSettingsPanel,
  setShowSettingsPanel,
  setBlocks,
  debouncedSave,
}) => {
  if (selectedBlock) {
    return (
      <EditorPanel
        imageUploadUrl={uploadUrl}
        selectedBlock={selectedBlock.block}
        onChange={(data) => handleBlockContentChange(
          data, setBlocks, selectedBlockId, debouncedSave,
        )}
        onCancel={() => setSelectedBlockId(null)}
        selectedBlockId={selectedBlockId}
      />
    )
  }

  if (showSettingsPanel) {
    return (
      <SettingsPanel
        email={email}
        onChange={onChange}
        onCancel={() => setShowSettingsPanel(false)}
      />
    )
  }

  return (
    <EmailBuilderPanel onClickSettings={() => setShowSettingsPanel(true)} />
  )
}

export default Email
