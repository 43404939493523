import React from "react"
import { DioboxEvent } from "sharedTypes"
import ColorTheme from "./ColorTheme/ColorTheme"
import PageWithPreview from "../PageWithPreview"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  eventPreview: DioboxEvent
  onChangeEventPreview: (values: Partial<DioboxEvent>) => void
}

const Colors = ({
  event,
  updateEvent,
  eventPreview,
  onChangeEventPreview,
}: Props) => (
  <PageWithPreview eventPreview={eventPreview}>
    <ColorTheme
      event={event}
      updateEvent={updateEvent}
      onChangeEventPreview={onChangeEventPreview}
    />
  </PageWithPreview>
)

export default Colors
