import React from "react"
import {
  Box, Stack, Text, VStack,
} from "@chakra-ui/react"
import Link from "components/elements/Link"
import Checkbox from "components/ReactHookForm/Checkbox"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { Account, DioboxEvent } from "sharedTypes"
import { GUEST_PORTAL_HELP_ARTICLE_LINK, getGuestPortalUrl } from "utilities/externalLinks"
import UpgradeAlert from "components/elements/UpgradeAlert"
import TitleWithSubtitle from "../TitleWithSubtitle"
import PageWithPreview from "../PageWithPreview"
import { DEFERRED_GUEST_REGISTRATION_INFO } from "./content"

type Props = {
  event: DioboxEvent
  updateEvent: (params: {}) => Promise<void>
  eventPreview: DioboxEvent
  account: Account
}

const GuestPortal = ({
  event,
  updateEvent,
  eventPreview,
  account,
}: Props) => {
  const onSubmit = (values) => updateEvent(values)
  const guestPortalUrl = getGuestPortalUrl(event.externalId)
  const { subscription } = account
  const isCorporate = subscription?.type === "Corporate"

  return (
    <PageWithPreview eventPreview={eventPreview}>
      <VStack align="flex-start" spacing={16}>
        <Box>
          <TitleWithSubtitle title="Guest Portal URL" mb={2} />
          <Link isExternal href={guestPortalUrl}><Text fontWeight="bold" fontSize="lg">{guestPortalUrl}</Text></Link>
          <Box
            borderWidth={1}
            borderRadius="md"
            px={6}
            py={4}
            mt={6}
          >
            <VStack align="flex-start" spacing={4}>
              <Text fontWeight="semibold" fontSize="lg">What is the Guest Portal?</Text>
              <Text>The Guest Portal is a collection of features that
                allows your attendees to interact with your event,
                including registration pages and email
                communication.
              </Text>
              <Text>
                To learn more, see {" "}
                <Link href={GUEST_PORTAL_HELP_ARTICLE_LINK} isExternal>
                  Guest Portal Help Article
                </Link>.
              </Text>
            </VStack>
          </Box>
        </Box>
        <Box>
          <TitleWithSubtitle
            title="Guest Actions"
            subtitle="Determine what your guests can do during and after they register for your event."
          />
          <ContainedForm values={event} onSubmit={onSubmit} name="Guest Actions" noPadding>
            {({ control }) => (
              <>
                <Box p={6} borderBottomWidth={1}>
                  <Checkbox
                    name="guestsCanUpdateProfile"
                    control={control}
                    label={<Text fontWeight="bold">Guests can modify their registration</Text>}
                    mb={0}
                  />
                  <Box ml={7}>
                    <Text>
                      Allow guests to edit their registration and their profile
                      (including their name and email address)
                      after they submit a form or purchase a ticket.
                    </Text>
                  </Box>
                </Box>
                <Box p={6} borderBottomWidth={1}>
                  <Checkbox
                    name="deferredTicketAssignmentEnabled"
                    control={control}
                    label={<Text fontWeight="bold">Enable deferred guest registrations</Text>}
                    mb={0}
                  />
                  <Box ml={7}>
                    <Text>{DEFERRED_GUEST_REGISTRATION_INFO}</Text>
                  </Box>
                </Box>
                <Stack p={6} spacing={4}>
                  <Checkbox
                    name="portalCaptchaEnabled"
                    control={control}
                    label={<Text fontWeight="bold">Enable Google reCAPTCHA for Registration Pages</Text>}
                    mb={0}
                    disabled={!isCorporate}
                  />
                  <Stack ml={7} spacing={4}>
                    <Text>By default, free ticket orders include Google reCAPTCHA
                      verification to ensure that each registration page is completed by a
                      human. This is important because unprotected registration pages
                      can be easily and quickly targeted by bots with malicious intent.
                    </Text>
                    <Text>In rare cases, you have the option to temporarily disable this feature
                      if it interferes with a large number of guest registrations on site. We
                      strongly recommend that you keep this feature enabled at all
                      other times to avoid potential bot interference.
                    </Text>
                    {!isCorporate && <UpgradeAlert noBorder text="Requires Corporate Subscription" />}
                  </Stack>
                </Stack>
              </>
            )}
          </ContainedForm>
        </Box>
      </VStack>
    </PageWithPreview>
  )
}

export default GuestPortal
