import React from "react"
import {
  DioboxEvent, AccountUser, FormsSubpath,
} from "sharedTypes"
import {
  Box, Stack, Text, VStack,
} from "@chakra-ui/react"
import SectionHeader from "pages/EventShow/Settings/SectionHeader"
import Checkboxes from "components/ReactHookForm/Checkboxes"
import UserListItem from "pages/EventShow/Guests/SelectedGuest/Show/CheckInAlert/UserListItem"
import ContainedForm from "components/ReactHookForm/ContainedForm"
import { eventAndAccountAdmins } from "utilities/objects"
import RadioButtons, { RadioOptionLabel } from "components/ReactHookForm/RadioButtons"
import { Boolean } from "utilities/enums"
import UpgradeAlert from "components/elements/UpgradeAlert"
import useCurrentAccount from "services/useCurrentAccount"
import Link from "components/elements/Link"
import { SEND_CC_MULTIPLE_ADDRESSES_ARTICLE_LINK } from "utilities/externalLinks"
import Card from "components/layout/Card"
import Button from "components/Buttons/Button"
import { Link as RouterLink } from "react-router-dom"
import { eventFormsPath } from "utilities/routes"

type Props = {
  event: DioboxEvent;
  teamMembers: AccountUser[]
  updateEvent: (params: {}) => Promise<void>;
}

const EmailNotifications = ({ event, teamMembers, updateEvent }: Props) => {
  const { subscription } = useCurrentAccount()

  const isCorporate = subscription?.type === "Corporate"

  const onSubmit = (values) => updateEvent(values)

  const {
    users, emailNotificationsUserIds, ccEmailsEnabled, id,
  } = event

  const allUsers = eventAndAccountAdmins(users, teamMembers)

  return (
    <Stack spacing={16}>
      <Stack spacing={6}>
        <SectionHeader>Automated Email Confirmations for Guests</SectionHeader>
        <Card>
          <Text>Use the <strong>Forms: Email Responders </strong>
            to customize the automated confirmation
            emails that are sent to guests when they register for your event.
          </Text>
          <Box mt={4} textAlign="center">
            <Button
              variant="outline"
              as={RouterLink}
              to={eventFormsPath(id, FormsSubpath.EmailResponders)}
            >Email Responder Settings
            </Button>
          </Box>
        </Card>
      </Stack>
      <CCEmails
        ccEmailsEnabled={ccEmailsEnabled}
        onSubmit={onSubmit}
        isCorporate={isCorporate}
      />
      <TeamNotifications
        users={allUsers.filter((eventUser) => eventUser.user)}
        emailNotificationsUserIds={emailNotificationsUserIds}
        onSubmit={onSubmit}
      />
    </Stack>
  )
}

export const AutomatedEmails = ({ sendConfirmationEmails, onSubmit }) => {
  const options = [
    { label: <><Text fontSize="lg" fontWeight="bold" mb={1}>Send Automated Diobox Email Confirmations</Text><Text>Let Diobox automatically send a confirmation email with the right messaging for an RSVP, ticket order, or a waitlist notification (Recommended).</Text></>, value: Boolean.True },
    { label: <><Text fontSize="lg" fontWeight="bold" mb={1}>Do not send confirmation emails</Text><Text>Your guests will not receive an automated confirmation message.</Text></>, value: Boolean.False },
  ]

  return (
    <>
      <ContainedForm
        values={{
          sendConfirmationEmails:
            sendConfirmationEmails ? Boolean.True : Boolean.False,
        }}
        onSubmit={
          ({
            sendConfirmationEmails:
            enabled,
          }) => onSubmit({ sendConfirmationEmails: enabled === Boolean.True })
        }
        name="Automated Email Confirmations for Guests"
      >
        {({ control }) => (
          <Box mt={3}>
            <RadioButtons
              name="sendConfirmationEmails"
              control={control}
              spacing={8}
              options={options}
            />
          </Box>
        )}
      </ContainedForm>
    </>
  )
}

const TeamNotifications = ({ users, emailNotificationsUserIds, onSubmit }) => (
  <Stack spacing={6}>
    <SectionHeader>Email Notifications for your Team</SectionHeader>
    <Text>
      Select members on your team who should receive an email message for ticket orders:
    </Text>
    <ContainedForm
      values={{ emailNotificationsUserIds }}
      onSubmit={onSubmit}
      name="Email Notifications for your Team"
    >
      {({ control }) => (
        <Checkboxes
          name="emailNotificationsUserIds"
          control={control}
          items={users.map(({ user }) => user)}
          itemComponent={UserListItem}
        />
      )}
    </ContainedForm>
  </Stack>
)

export const CCEmails = ({ ccEmailsEnabled, onSubmit, isCorporate }) => (
  <Stack spacing={6}>
    <SectionHeader>CC Email Addresses on the Guest Profile</SectionHeader>
    <Text>When a guest has more than 1 email address:</Text>
    <ContainedForm
      values={{ ccEmailsEnabled: ccEmailsEnabled ? Boolean.True : Boolean.False }}
      onSubmit={
        ({ ccEmailsEnabled: enabled }) => onSubmit({ ccEmailsEnabled: enabled === Boolean.True })
      }
      name="CC Email Addresses on the Guest Profile"
    >
      {({ control }) => (
        <>
          <CCEmailsFormFields control={control} isCorporate={isCorporate} />
          {!isCorporate && <UpgradeAlert text="This feature requires a Corporate Subscription." />}
        </>
      )}
    </ContainedForm>
  </Stack>
)

export const CCEmailsFormFields = ({ control, isCorporate }) => (
  <VStack pt={4} spacing={8} align="baseline">
    <RadioButtons
      name="ccEmailsEnabled"
      control={control}
      spacing={6}
      options={[
        {
          label: <RadioOptionLabel
            title="Send to the Primary Email Address"
            description="The guest receives the email message on their primary email address."
          />,
          value: Boolean.False,
        },
        {
          label: <RadioOptionLabel
            disabled={!isCorporate}
            title="Send CC to All Email Addresses"
            description={(
              <VStack pt={1} spacing={2} align="baseline">
                <Text>
                  A copy of the same email message is sent to all email addresses on the guest
                  profile.
                </Text>
                <Text>
                  This is useful when guest has an assistant, manager, or a support team and
                  you want everyone associated with the guest receive the same invite or the
                  RSVP link.
                </Text>
                <Text>
                  <>To learn more, see: </>
                  <Link target="_blank" href={SEND_CC_MULTIPLE_ADDRESSES_ARTICLE_LINK}>How to CC Emails to Multiple Guest Email Addresses</Link>.
                </Text>
              </VStack>
          )}
          />,
          value: Boolean.True,
          disabled: !isCorporate,
        },
      ]}
    />
  </VStack>
)

export default EmailNotifications
