import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { DioboxEvent, TextMessage } from "sharedTypes"
import { useTextMessage } from "queries/texts"
import Spinner from "components/elements/Spinner"
import { useMutation, useQueryClient } from "react-query"
import * as api from "api/texts"
import { refetchTextMessages, updateTextMessage } from "context/actions"
import { eventTextingPath, eventTextMessagePath } from "utilities/routes"
import { successToast } from "utilities/toasts"
import DraftTextMessage from "./DraftTextMessage/DraftTextMessage"
import SentTextMessage from "./SentTextMessage/SentTextMessage"

export const TEXT_MESSAGE_BODY_CHARACTER_LIMIT = 290

type Props = {
  textMessageId: string
  event: DioboxEvent
  enabled: boolean
  isCorporate: boolean
}

const SelectedTextMessage = ({
  textMessageId, event: { id: eventId }, event, enabled, isCorporate,
}: Props) => {
  const {
    isLoading: textMessageIsLoading,
    data: selectedTextMessage,
  } = useTextMessage(eventId, textMessageId)
  const queryClient = useQueryClient()
  const history = useHistory()
  const [showSavedLabel, setShowSavedLabel] = useState(false)

  const { mutate: handleDelete } = useMutation(
    () => api.deleteTextMessage(eventId, textMessageId), {
      onSuccess: () => {
        refetchTextMessages(queryClient, eventId)
        history.push(eventTextingPath(eventId))
      },
    },
  )

  const handleSave = async (textMessage: TextMessage) => {
    const { data } = await api.putTextMessage(eventId, textMessageId, textMessage)

    setShowSavedLabel(true)
    setTimeout(() => {
      setShowSavedLabel(false)
    }, 2000)

    updateTextMessage(queryClient, textMessageId, data)
    refetchTextMessages(queryClient, eventId)
  }

  const generateDuplicatedTextName = (textMessage: TextMessage) => {
    const { name } = textMessage

    // Match a name ending with a number
    const match = name.match(/^(.*?)(\d+)$/)

    if (match) {
      const baseName = match[1].trim()
      const number = parseInt(match[2], 10)

      return `${baseName} ${number + 1}`
    }

    // If the name doesn't end with a number, add " 2" to the end
    return `${name} 2`
  }

  const handleDuplicate = async (textMessage: TextMessage) => {
    const { data: { externalId } } = await api.postTextMessage(eventId,
      { ...textMessage, name: generateDuplicatedTextName(textMessage) })
    await refetchTextMessages(queryClient, eventId)

    history.push(eventTextMessagePath(eventId, externalId))
    successToast({ title: "Text message successfully duplicated" })
  }

  if (textMessageIsLoading) {
    return <Spinner />
  }

  if (!selectedTextMessage) {
    return null
  }

  if (selectedTextMessage?.sentAt) {
    return (
      <SentTextMessage
        selectedTextMessage={selectedTextMessage}
        onSave={handleSave}
        onDelete={handleDelete}
        event={event}
        onDuplicate={handleDuplicate}
      />
    )
  }

  return (
    <DraftTextMessage
      event={event}
      selectedTextMessage={selectedTextMessage}
      onSave={handleSave}
      onDelete={handleDelete}
      showSavedLabel={showSavedLabel}
      enabled={enabled}
      isCorporate={isCorporate}
      onDuplicate={handleDuplicate}
    />
  )
}

export default SelectedTextMessage
