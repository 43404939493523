import React, { Dispatch } from "react"
import { Box, Text } from "@chakra-ui/react"
import { useCurrentEvent } from "queries/events"
import {
  BuilderBlockWithBlockId,
  FixedBlockType,
  Form,
  FormType,
} from "sharedTypes"
import { BlockInsertPosition } from "components/Builder/BuildingBlock"
import { DraggableItem } from "sharedTypes/builder"
import FormSubmitButton from "pages/EventShow/Forms/Forms/SelectedForm/FormSubmitButton"
import { asMoney } from "utilities/strings"
import { formIconAndType } from "utilities/forms"
import PaymentFieldsBlock from "../FormPaymentFields"
import FormPlusOnes from "../FormPlusOnes/FormPlusOnes"
import Blocks from "../Blocks"
import FixedBlock from "../FixedBlock"
import FormSection from "../../FormSection"
import FormHeader from "../FormHeader"
import TopFixedBlock from "./TopFixedBlock"

type Props = {
  form: Form
  blocks: BuilderBlockWithBlockId[]
  editable?: boolean
  setBlocks: Dispatch<React.SetStateAction<BuilderBlockWithBlockId[]>>
  onDeleteBlock: () => void
  setSelectedBlockId?: (blockId: number) => void
  onSelectFixedBlockType?: (blockType: FixedBlockType|undefined) => void
  selectedBlock?: BuilderBlockWithBlockId
  selectedFixedBlockType?: FixedBlockType
  onDuplicateBlock: (blockId: number) => void
  currency: string
  onDropBlock?: (item: DraggableItem, blockId: number, position: BlockInsertPosition) => void
}

const FormContainer = ({
  form,
  blocks,
  editable,
  setSelectedBlockId,
  selectedBlock,
  setBlocks,
  onDeleteBlock,
  onSelectFixedBlockType,
  selectedFixedBlockType,
  onDuplicateBlock,
  currency,
  onDropBlock,
}: Props) => {
  const { data: { portalPrimaryColor } } = useCurrentEvent()
  const {
    type,
    name,
    translations: {
      rsvpSubmitButton,
      registrationSubmitButton,
      ticketSubmitButton,
      waitlistSubmitButton,
      rsvpConfirm,
      rsvpDecline,
      rsvpPlusOneLabel,
      payment,
      surveySubmitButton,
    },
    description,
    variablePrice,
    price,
  } = form

  const handleSelectBlock = (blockId: number) => {
    setSelectedBlockId?.(blockId)
    onSelectFixedBlockType?.(undefined)
  }

  const generateSubmitButtonText = () => {
    switch (type) {
      case FormType.RSVP:
        return rsvpSubmitButton
      case FormType.Registration:
        return registrationSubmitButton
      case FormType.Ticket:
        return `${ticketSubmitButton} ${!variablePrice ? asMoney(price, currency) : ""}`
      case FormType.Waitlist:
        return waitlistSubmitButton
      case FormType.Survey:
        return surveySubmitButton
      default:
        return ""
    }
  }

  return (
    <Box
      m="auto"
      position="relative"
      mt={8}
    >
      <FormHeader icon={formIconAndType(type).icon}>
        <Box>
          <Text fontSize="2xl" fontWeight="semibold" lineHeight="normal">{name}</Text>
          <Text>{description}</Text>
        </Box>
      </FormHeader>
      <Box backgroundColor="white">
        <TopFixedBlock
          form={form}
          editable={editable}
          selectedFixedBlockType={selectedFixedBlockType}
          onDropBlock={onDropBlock}
          rsvpConfirm={rsvpConfirm}
          rsvpDecline={rsvpDecline}
          portalPrimaryColor={portalPrimaryColor}
          onSelectFixedBlockType={onSelectFixedBlockType}
        />
        <Blocks
          blocks={blocks}
          editable={editable}
          setSelectedBlockId={handleSelectBlock}
          selectedBlock={selectedBlock}
          setBlocks={setBlocks}
          onDeleteBlock={onDeleteBlock}
          onDuplicateBlock={onDuplicateBlock}
          onDropInto={onDropBlock}
        />
        <Box>
          {type === FormType.Ticket && (
            <FixedBlock
              editable={editable}
              selected={selectedFixedBlockType === FixedBlockType.Payment}
              onSelect={() => onSelectFixedBlockType?.(FixedBlockType.Payment)}
              onDropInto={onDropBlock}
              blockInsertPosition={BlockInsertPosition.Top}
            >
              <PaymentFieldsBlock
                submitButtonColor={portalPrimaryColor}
                submitButtonText={generateSubmitButtonText()}
                headerText={payment}
              />
            </FixedBlock>
          )}
          {type === FormType.RSVP && (
            <FixedBlock
              editable={editable}
              selected={selectedFixedBlockType === FixedBlockType.PlusOnes}
              onSelect={() => onSelectFixedBlockType?.(FixedBlockType.PlusOnes)}
              onDropInto={onDropBlock}
              blockInsertPosition={BlockInsertPosition.Top}
            >
              <FormPlusOnes label={rsvpPlusOneLabel} form={form} />
            </FixedBlock>
          )}
          {type !== FormType.Ticket && (
            <FixedBlock
              editable={editable}
              selected={selectedFixedBlockType === FixedBlockType.FormSubmission}
              onSelect={() => onSelectFixedBlockType?.(FixedBlockType.FormSubmission)}
              onDropInto={onDropBlock}
              blockInsertPosition={BlockInsertPosition.Top}
              allowDropInto={!form.plusOnesEnabled}
            >
              <FormSection last>
                <FormSubmitButton
                  color={portalPrimaryColor}
                >{generateSubmitButtonText()}
                </FormSubmitButton>
              </FormSection>
            </FixedBlock>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default FormContainer
