import React, { ReactNode } from "react"
import Menu, { MenuDivider, MenuItem } from "components/dialogs/Menu"
import useFeatureFlag from "services/featureFlags"

type Props = {
  button: string | ReactNode
  order: string
  onSetOrder: (order) => void
  allSelected?: boolean
  toggleSelectAll?: () => void
}

const GuestsMenu = ({
  button, order, onSetOrder, allSelected, toggleSelectAll,
}: Props) => {
  const { guestTimestamps: guestTimestampsEnabled } = useFeatureFlag()

  const nameSortingItems = [
    {
      label: "First Name",
      id: "first_name_asc",
    }, {
      label: "Last Name",
      id: "last_name_asc",
    },
  ].map(({ label, id }) => (
    <MenuItem
      key={id}
      onClick={() => onSetOrder(id)}
      selected={order === id}
      px={10}
    >
      Sort by {label}
    </MenuItem>
  ))

  const dateSortingItems = [
    {
      label: "Date Created",
      id: "created_at_desc",
    }, {
      label: "Date Modified",
      id: "updated_at_desc",
    },
    {
      label: "Date Confirmed",
      id: "confirmed_at_desc",
    },
  ].map(({ label, id }) => (
    <MenuItem
      key={id}
      onClick={() => onSetOrder(id)}
      selected={order === id}
      px={10}
    >
      Sort by {label}
    </MenuItem>
  ))

  const selectAll = toggleSelectAll ? [
    <MenuItem
      key="select-all"
      onClick={toggleSelectAll}
      px={10}
    >
      {allSelected ? "Clear Selection" : "Select All"}
    </MenuItem>,
  ] : []

  return (
    <Menu
      button={button}
      title="Options"
      placement="bottom-end"
      gutter={16}
      menuItems={[
        ...selectAll,
        <MenuDivider />,
        ...nameSortingItems,
        ...(guestTimestampsEnabled
          ? [
            <MenuDivider key="divider-2" />,
            ...dateSortingItems,
          ]
          : []),
      ]}
    />
  )
}

export default GuestsMenu
